import React, {useEffect} from "react";
import "./errorMessages.css";

function UploadError(props: any) {

    return <div className="outer">
        <div className="background">
            <div className="fontSizeTitle">Die Dateien konnten nicht der Datenbank hinzugefügt werden</div>

            {!props.upload_msg && (
                <div>
                    <p>Mögliche Gründe dafür sind:</p>
                    <ul>
                        <li>Fehlende Informationen - Alle Felder sind Pflichtfelder</li>
                        <li>Falscher Dateityp - Es muss eine CSV-Datei sein</li>
                        <li>Fehlende Preisspiegel oder fehlendes Leistungsverzeichnis</li>
                    </ul>
                </div>
            )}
            {props.upload_msg && (
                <div>
                    <p>{props.upload_msg}</p>
                </div>
            )}
        </div>


    </div>
}

export default UploadError
