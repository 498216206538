import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import React, {useState} from "react";

function InputSelect(props: any){
    const [select_value, set_select_value] = useState(null);

    function onBackspace(e: any){
        if (e.keyCode === 8) {
            set_select_value(null);
        }
    }

    function on_change(choice: any){
        if (!props.isMulti){
            choice = choice.value;
        }
        props.set_function(choice);
        set_select_value(choice);
    }

    return <div className={props.outerClassName ?? ''}>
        {props.creatable_select && (
            <div>
                <CreatableSelect className={props.className}
                                 isSearchable={true}
                                 isMulti={props.isMulti}
                                 placeholder={props.placeholder}
                                 options={props.items}
                                 onChange={choice => {on_change(choice)}}
                                 onKeyDown={onBackspace}
                                 formatCreateLabel={value => `Search for ${value}`}
                                 value={props.value}
                                 classNamePrefix={props.classNamePrefix}
                />
            </div>
        )}
        {!props.creatable_select && (
            <div>
                <Select className={props.className}
                        isSearchable={true}
                        isMulti={props.isMulti}
                        placeholder={props.placeholder}
                        options={props.items}
                        onChange={choice => {on_change(choice)}}
                        onKeyDown={onBackspace}
                        value={props.value}
                        classNamePrefix={props.classNamePrefix}
                />
            </div>
        )}
    </div>
}

export default InputSelect