import React, { useState } from "react";
import "../detail.css";

function Hint(props: any) {
    const [hintIsHidden, setHintIsHidden] = useState(true);
    const [timeoutId, setTimeoutId] = useState<any>(null);

    function getHintStyles() {
        let styles: any = { display: hintIsHidden ? 'none' : 'inline' };
        if (props.top) {
            styles.top = props.top;
        }
        return styles;
    }

    function handleMouseEnter() {
        if (timeoutId) {
            clearTimeout(timeoutId);
            setTimeoutId(null);
        }
        setHintIsHidden(false);
    }

    function handleMouseLeave() {
        const id = setTimeout(() => {
            setHintIsHidden(true);
        }, 200);
        setTimeoutId(id);
    }

    return (
        <div className={props.className} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div>
                {props.hintedComponent}
            </div>
            <div>
                <div className={'hintPosition'} style={getHintStyles()}>
                    <span className={props.hintStyle}>{props.textHint}</span>
                </div>
            </div>
        </div>
    );
}

export default Hint;
