import React, {useState, useEffect} from "react";

function LvBar(props: any) {
    const max_craft_count = 20;
    const lv_bar_width = 350;
    const bar_left_width = lv_bar_width * (props.craft_count / max_craft_count)
    const bar_right_width = lv_bar_width - bar_left_width

    function get_styles(width: number, color: string, l: boolean){
        let border_radius;
        if(width === lv_bar_width){
            border_radius = `5px 5px 5px 5px`
        }
        else{
            border_radius = `${l ? 5 : 0}px ${l ? 0 : 5}px ${l ? 0 : 5}px ${l ? 5 : 0}px`
        }

        return {
            backgroundColor: color,
            minWidth: `${width}px`,
            display: "inline-block",
            borderRadius: border_radius,
            height: "10px",
        };
    }

    return (
        <div className="lvBarOuter">
            {props.craft_count} Leistungsverzeichnis{props.craft_count !== 1 ? "se" : ""}
            <div>
                {bar_left_width > 0 && (
                    <div style={get_styles(bar_left_width, "#66C1BF", true)}>&nbsp;</div>
                )}
                {bar_right_width > 0 && (
                    <div style={get_styles(bar_right_width, "#8282824c", false)}>&nbsp;</div>
                )}
            </div>
        </div>
    )
}

export default LvBar