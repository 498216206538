import React, {useEffect} from "react";
import "./errorMessages.css";
import sad_face from "../../images/sad_face.svg";
import {useNavigate} from "react-router-dom";

function ProjectsEmpty() {
    let navigate = useNavigate();

    return <div className="outer">
        <div className="background marginBottom">
            <div className="fontSizeTitle margin2">Du hast anscheinend noch keine Projekte angelegt</div>
            <div className="center margin2"><img src={sad_face} alt=":(" /></div>
            <div className="fontSizeTitle margin2">Hier kannst du neue Projekte anlegen oder erweitern</div>
            <div className="center margin2"><button onClick={() => navigate("/upload")} className="goToUploadBtn">Datenbank erweitern</button></div>
        </div>
    </div>
}

export default ProjectsEmpty
