import React, {useEffect, useState} from "react";
import "../pricebar.css";
import arrow from "../images/pfeil.svg"

import lupe from "../images/lupe.svg";

function PriceBar(props: any) {
    const [left, set_left] = useState<string[]>(props.left.length > 0 ? props.left.split(",") : []);
    const [middle, set_middle] = useState<string[]>(props.middle.length > 0 ? props.middle.split(",") : []);
    const [right, set_right] = useState<string[]>(props.right.length > 0 ? props.right.split(",") : []);

    function merge_arrays(a1: any[], a2: any[]): any[]{
        let merged_array: any[] = [];
        a1.forEach((e) => {
            merged_array.push(e);
        });
        a2.forEach((e) => {
            merged_array.push(e);
        });
        return merged_array;
    }

    function left_prices_clicked(){
        if (left.length < 1){
            return;
        }
        let price: string = left[left.length - 1];
        set_left(left.slice(0, -1));
        set_middle(merge_arrays([price], middle));
    }

    function middle_left_prices_clicked(){
        if (middle.length < 1){
            return;
        }
        let price: string = middle[0];
        set_middle(middle.slice(1, middle.length));
        set_left(merge_arrays(left, [price]));
    }

    function middle_right_prices_clicked(){
        if (middle.length < 1){
            return;
        }
        let price: string = middle[middle.length - 1];
        set_middle(middle.slice(0, -1));
        set_right(merge_arrays([price], right));
    }

    function right_prices_clicked(){
        if (right.length < 1){
            return;
        }
        let price: string = right[0];
        set_right(right.slice(1, right.length));
        set_middle(merge_arrays(middle, [price]));
    }

    function average_of_prices(): number{
        let sum: number = 0;
        middle.forEach((price) => {
            
            sum += +price;
        });
        return sum / middle.length;
    }

    return <div className="aboveEachOther center">
        <div className="nextEachOther">
            <div className="contentRight aboveEachOther">
                <div className="nextEachOther">
                    {
                        left.map((price: any, index: any) => (
                            <div className="priceDiv">{props.price_formatter(price)}</div>
                        ))
                    }
                </div>
                <div className="moveRight" onClick={left_prices_clicked}>
                    <view>
                        <img className="arrows arrowRotate" src={arrow} alt="lupe"/>
                    </view>
                </div>
            </div>

            <div className="barrier">{""}</div>

            <div className="aboveEachOther">
                <div className="nextEachOther">
                    {
                        middle.map((price: any, index: any) => (
                            <div className="priceDiv">{props.price_formatter(price)}</div>
                        ))
                    }
                </div>
                <div className="spaceAround">
                    <div onClick={middle_left_prices_clicked}>                    <view>
                        <img className="arrows" src={arrow} alt="lupe"/>
                    </view>
                    </div>
                    <div onClick={middle_right_prices_clicked}>
                        <view>
                            <img className="arrows arrowRotate" src={arrow} alt="lupe"/>
                        </view>
                    </div>
                </div>
            </div>

            <div className="barrier">{""}</div>

            <div className="aboveEachOther">
                <div className="nextEachOther">
                    {
                        right.map((price: any, index: any) => (
                            <div className="priceDiv">{props.price_formatter(price)}</div>
                        ))
                    }
                </div>
                <div className="moveLeft" onClick={right_prices_clicked}>
                    <view>
                        <img className="arrows" src={arrow} alt="lupe"/>
                    </view>
                </div>
            </div>
        </div>
        <div className="nextEachOther">
            <div className="aboveEachOther">
                <div className="costIndexText">Neuer Kostenkennwert:</div>
                <div className="costIndexText">Neuer indizierter Kostenkennwert: </div>
            </div>
            <div className="aboveEachOther">
                <div className="costIndex">{props.price_formatter(average_of_prices())}</div>
                <div className="costIndex">{props.price_formatter(average_of_prices() * props.price_factor)}</div>
            </div>
        </div>
    </div>
}

export default PriceBar
