import React, {useState} from 'react';
import { Route, Routes } from "react-router-dom";

import '../App.css';
import { UserContext } from "../UserContext";

import Login from "../login";
import Menubar from "./Menubar";
import SearchPositionsPage from "../pages/SearchPositions";
import Upload from "../pages/Upload";
import Projects from "../pages/Projects";
import ForgotPassword from "../pages/ForgotPassword";
import Footer from "./Footer";
import Imprint from './Imprint';

function AppLayout() {
    const [current_page, set_current_page] = useState("/search-positions");

    return (
        <div className="mainContent">
            <UserContext.Provider value={null}>
                <Menubar current_page={current_page} set_current_page={set_current_page}/>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/logout" element={<Login />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />

                    <Route path="/search-positions" element={<SearchPositionsPage />} />
                    <Route path="/upload" element={<Upload />} />
                    <Route path="/projects" element={<Projects current_page={current_page} set_current_page={set_current_page}/>} />

                    <Route path="/imprint" element={<Imprint/>} />
                </Routes>
                <Footer current_page={current_page} set_current_page={set_current_page}/>
            </UserContext.Provider>
        </div>
    );
}

export default AppLayout;
