import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom";
import DataTable, { ExpanderComponentProps } from "react-data-table-component";
import Card from "@material-ui/core/Card";
import SortIcon from "@material-ui/icons/ArrowDownward";
import "../styles.css";
import PositionDetail from "../components/PositionDetail";
import green_dot from "../images/green_dot.svg";
import red_dot from "../images/red_dot.svg";
import sort from "../images/sort.svg";
import sortUp from "../images/sortUp.svg";
import sortDown from "../images/sortDown.svg";

function SearchTable(props: any) {
    const [clickedRow, setClickedRow] = useState<Row | null>(null);
    const [sortAsc, setSortAsc] = useState(true)
    const [sortedColumn, setSortedColumn] = useState<string | null>(null)
    
    const shown_columns = [
        {"label": "Kurztext", "key": "short-description", "fontWeight": "bold"},
        {"label": "Langtext", "key": "long-description", "fontWeight": "none"},
        {"label": "Menge", "key": "count", "fontWeight": "none"},
        {"label": "Mengeneinheit", "key": "unit", "fontWeight": "none"},
        {"label": "Indizierter Kostenkennwert", "key": "indexed-cost-characteristic", "fontWeight": "none"},
        {"label": "Auftragsvolumen", "key": "project-size", "fontWeight": "none"},
        {"label": "Gruppe", "key": "family-id", "fontWeight": "none"},
        {"label": "Parent", "key": "is-parent", "fontWeight": "none"},
        {"label": "Kostenkennwert", "key": "cost-characteristic", "fontWeight": "none"},
        {"label": "Ausreißer niedrig", "key": "low-prices", "fontWeight": "none"},
        {"label": "Preise", "key": "accepted-prices", "fontWeight": "none"},
        {"label": "Ausreißer hoch", "key": "high-prices", "fontWeight": "none"},
    ];

    function get_table_widths(col_key: string) {
        let min_col_widths: any = {
            "id": {"min": 18, "proportion": 0},
            "short-description": {"min": 100, "proportion": 0.2},
            "long-description": {"min": 100, "proportion": 0.8},
            "count": {"min": 18, "proportion": 0},
            "unit": {"min": 18, "proportion": 0},
            "indexed-cost-characteristic": {"min": 23, "proportion": 0},
            "project-size": {"min": 28, "proportion": 0},
            "family-id": {"min": 10, "proportion": 0},
            "is-parent": {"min": 10, "proportion": 0},
            "cost-characteristic": {"min": 28, "proportion": 0},
            "low-prices": {"min": 28, "proportion": 0},
            "accepted-prices": {"min": 28, "proportion": 0},
            "high-prices": {"min": 28, "proportion": 0},
        };
        let table_vh = 0.85
        let screen_width = window.innerWidth;
        let left_over = table_vh * screen_width
        for (let col of Object.keys(min_col_widths)) {
            left_over -= min_col_widths[col]["min"];
        }
        let col_width_float = min_col_widths[col_key]["min"] + min_col_widths[col_key]["proportion"] * table_vh * left_over;
        let col_width = col_width_float | 0;
        let col_width_str = col_width.toString();
        return col_width_str + "px";
    }

    function format_price(price: number | string) {
        if (typeof price === "string"){
            price = +price;
        }
        let price_rounded = Number((price).toFixed(2))
        let price_str = price_rounded.toString();
        if (!price_str.includes('.')){
            return add_reference_points_to_number(price_rounded, false) + ",00";
        }
        let price_split = price_str.split(".")
        let price_euro, price_cent;
        if(price_split.length === 2) {
            [price_euro, price_cent] = price_split;
        }
        else {
            return null;
        }
        let counter = 0;
        let new_price_euro = "";
        price_euro.split("").reverse().forEach(char => {
            if(counter === 3){
                new_price_euro += ".";
                counter = 0;
            }
            new_price_euro += char;
            counter += 1;
        });
        price_euro = new_price_euro.split("").reverse().join("");

        let cent_chars_split = price_cent.toString().split("");
        cent_chars_split = cent_chars_split.concat(["0", "0"]);
        let [cent_chars_split_d0, cent_chars_split_d1] = cent_chars_split;
        if (cent_chars_split_d1 === "0" && cent_chars_split_d0 === "0"){
            return `${price_euro},0`;
        }
        else {
            return `${price_euro},${cent_chars_split_d0 + cent_chars_split_d1}`;
        }
    }

    function add_reference_points_to_number(number: number, with_euro: boolean = true, isFloat = false) {
        let counter = 0;
        let new_number = "";
        let decimalNumberPart = '';
        if (isFloat) {
            let integerNumberPart = number.toString().split('.')[0]
            if (number.toString().includes('.')) {
                decimalNumberPart = number.toString().split('.')[1]
            }
            integerNumberPart.toString().split("").reverse().forEach(char => {
                if(counter === 3){
                    new_number += ".";
                    counter = 0;
                }
                new_number += char;
                counter += 1;
            })
        }
        else {
            number.toString().split("").reverse().forEach(char => {
                if(counter === 3){
                    new_number += ".";
                    counter = 0;
                }
                new_number += char;
                counter += 1;
            })
        }
        // 
        return new_number.split("").reverse().join("") + (decimalNumberPart !== '' ? ',' + decimalNumberPart : '') + (with_euro ? " €" : "");
    }

    function get_cell_value(key: string, row: any) {
        let cell_value = row[key];
        if(key === "project-size"){
            return add_reference_points_to_number(cell_value);
        }
        else if(["indexed-cost-characteristic", "cost-characteristic"].includes(key)){
            return format_price(cell_value) + ` €/${row["unit"]}`;
        }
        else if(["low-prices", "accepted-prices", "high-prices"].includes(key)){
            return cell_value.replaceAll(',', ',  ');
        }
        else{
            return cell_value;
        }
    }

    function color(row: any) {
        let class_name = "tableCell"
        if (row["is-parent"] != null) {
            class_name += " blueRow"
        }
        else if (row["family-id"] != null) {
            class_name += " lightBlueRow"
        }

        return class_name;
    }

    function long_description_width(): number{
        return Math.max(window.innerWidth * 0.96 - 1120 - 20, 400);
    }

    function get_background_color(row: any) {
        if (row.is_parent) {
            return '#58A0D6';
        }
        else if (row.family_id !== null) {
            return '#cae6ef'
        }
        return 'white';
    }

    function get_row_style() {
        let font_family = 'Arial, Helvetica, sans-serif';
        let lineHeight = 'normal';
        let fontWeight = 400;
        let fontSize = 16;
        let maxHeight = 10;

        return {fontSize: 16, maxHeight: 65, overflow: "hidden"};
    }

    function getSortIcon(column: any) {
        let style = {width: '18px', height: '18px', margin: '3px 0 0 3px'}
        let sortIcon = sort
        if (column === sortedColumn) {
            sortIcon = sortAsc ? sortUp : sortDown
        }

        return <img style={style} alt={'Sortieren'} src={sortIcon} onClick={() => {
            if (column === sortedColumn) {
                if (sortAsc) {
                    setSortAsc(false)
                }
                else {
                    setSortedColumn(null)
                    setSortAsc(true)
                }
            }
            else {
                setSortedColumn(column)
                setSortAsc(true)
            }
        }}/>
    }

    function standardSort(a: any,  b: any) {
        if (typeof a === 'string' && typeof b === 'string') {
            return ((a.toLowerCase() < b.toLowerCase()) !== sortAsc) ? -1 : 1;
        }
        return ((a < b) !== sortAsc) ? -1 : 1;
    }
    
    useEffect(() => {
        if (sortedColumn !== null) {
            const filtered = props.positions.filter((item: any) => item.family_id === null || item.is_parent !== null);
            filtered.sort((a: any, b: any) => {return standardSort(a[sortedColumn], b[sortedColumn])});
            
            let remaining = props.positions.filter((item: any) => !(item.family_id === null || item.is_parent !== null));
            remaining.sort((a: any, b: any) => {return standardSort(a[sortedColumn], b[sortedColumn])});

            remaining.forEach((remainingItem: any) => {
                const index = filtered.findIndex((item: any) => item.family_id === remainingItem.family_id && item.is_parent === 1);
                filtered.splice(index + 1, 0, remainingItem);
            });
    
            props.setPositions(filtered)
        }
    }, [sortedColumn, setSortedColumn, sortAsc, setSortAsc]);
    
    const columns: any[] = [
        {
            name: <div className="searchTableHeader flexdirectionRow"><div>Kurztext </div>{getSortIcon('short_description')}</div>,
            selector: "short_description",
            width: "300px",
            cell: (row: Row) => <div onClick={() => setClickedRow(row === clickedRow ? null : row)} style={get_row_style()}>{row.short_description}</div>
        },
        {
            name: <div className="searchTableHeader flexdirectionRow"><div>Langtext </div>{getSortIcon('long_description')}</div>,
            selector: "long_description",
            width: long_description_width().toString() + "px",
            cell: (row: any) => <div onClick={() => setClickedRow(row === clickedRow ? null : row)} style={get_row_style()}>{row.long_description}</div>
        },
        {
            name: <div className="searchTableHeader flexdirectionRow"><div>Menge </div>{getSortIcon('count')}</div>,
            selector: "count",
            width:"120px",
            cell: (row: any) => <div onClick={() => setClickedRow(row === clickedRow ? null : row)} style={get_row_style()}>{add_reference_points_to_number(row.count, false, true)}</div>
        },
        {
            name: <div className="searchTableHeader flexdirectionRow"><div>Einheit </div>{getSortIcon('unit')}</div>,
            selector: "unit",
            width:"120px",
            cell: (row: any) => <div onClick={() => setClickedRow(row === clickedRow ? null : row)} style={get_row_style()}>{`${props.available_units[row['unit']] ?? '-'}`.replace('/', '/ ')}</div>,
        },
        {
            name: <div className="searchTableHeader flexdirectionRow"><div>Indizierter Kostenkennwert (€/ Einheit) </div>{getSortIcon('indexed_cost_characteristic')}</div>,
            selector: "indexed_cost_characteristic",
            width:"190px",
            cell: (row: any) => <div onClick={() => setClickedRow(row === clickedRow ? null : row)} style={get_row_style()}>{format_price(row['indexed_cost_characteristic'])}</div>,
        },
        {
            name: <div className="searchTableHeader flexdirectionRow"><div>Auftragsvolumen (€) </div>{getSortIcon('project_size')}</div>,
            selector: "project_size",
            width:"190px",
            cell: (row: any) => <div onClick={() => setClickedRow(row === clickedRow ? null : row)} style={get_row_style()}>{add_reference_points_to_number(row["project_size"], false)}</div>,
        },
        {
            name: <div className="searchTableHeader flexdirectionRow"><div>Validiert </div>{getSortIcon('is_valid')}</div>,
            selector: "is_valid",
            cell: (row: any) => <div onClick={() => setClickedRow(row === clickedRow ? null : row)} style={{display: 'flex', justifyContent: 'center', width: '100%', maxWidth: 64}}>
                <view>
                    <img style={{maxWidth: 10, maxHeight: 10}} src={row["is_valid"] ? green_dot : red_dot} alt={row["is_valid"] ? "ja" : "nein"}/>
                </view>
            </div>,
        },
    ];
    
    interface Props extends ExpanderComponentProps<Row> {
        // currently, props that extend ExpanderComponentProps must be set to optional.
    }

    const ExpandableRowComponent: React.FC<Props> = ({ data }) => {
        return (
            <>
                <PositionDetail row={data} ref_points={add_reference_points_to_number} price_formatter={format_price}/>
            </>
        );
    };

    const conditionalRowStyles = [
        {
            when: (row: any) => true,
            style: (row: any) => ({ backgroundColor: get_background_color(row) }),
        },
    ];

    interface Row{
        "index" : any;
        "id": any;
        "short_description": any;
        "long_description": any;
        "count": any;
        "unit": any;
        "indexed_cost_characteristic": any;
        "project_size": any;
        "family_id": any;
        "is_parent": any;
        "cost_characteristic": any;
        "low_prices": any;
        "accepted_prices": any;
        "high_prices": any;
        "project_name": any;
        "craft_name": any;
        "craft": any;
        "category": any;
        "year": any;
        "month": any;
        "oz": any;
        "is_valid": any;
        "project_id": any;
    }

    return <div className="tableAll">
        <Card>
            {props.resultCount} Ergebnisse
            <DataTable
                columns={columns}
                data={props.positions}
                defaultSortFieldId="short-description"
                sortIcon={<SortIcon />}
                expandableRows
                expandableRowsComponent={ExpandableRowComponent}
                // pagination
                // paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                // paginationComponentOptions={{ rowsPerPageText: 'Einträge pro Seite:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }}
                conditionalRowStyles={conditionalRowStyles}
                expandableRowExpanded={(row: Row) => {
                    return clickedRow === row;
                }}
                pointerOnHover
                onRowClicked={(row) => {
                    setClickedRow(row === clickedRow ? null : row)
                }}
            />
        </Card>
    </div>
}

export default SearchTable
