import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import "../styles.css";
import logo from "../images/logo.svg";
import db_search from "../images/db_search.svg";
import db_search_selected from "../images/db_search_selected.svg";
import db_eye from "../images/db_eye.svg";
import db_eye_selected from "../images/db_eye_selected.svg";
import logout_img from "../images/logout.svg";

function Menubar(props: any) {
    let navigate = useNavigate();
    let [last_updated, set_last_updated] = useState<number>(0);
    let [logged_in, set_logged_in] = useState<boolean>(false);

    let request_options = {
        headers: { "Content-Type": "application/json" },
        method: 'POST',
        body: JSON.stringify({ "username": "", "password": "" })
    };

    useEffect(() => {
        if (new Date().getTime() - last_updated < 1000) {
            return;
        }
        fetch("/login", request_options)
            .then((response) => response.json())
            .then((response) => {
                set_last_updated(new Date().getTime());
                if (response["logged_in"] !== logged_in)
                {
                    set_logged_in(response["logged_in"]);
                }
            })
            .catch(error => {
                console.error(error);
            });
    })

    useEffect(() => {
        if (logged_in) {
            navigate(props.current_page);
        }
        else {
            if (props.current_page === '/imprint') {
                navigate("/imprint");
            }
            else {
                navigate("/login");
            }
        }
    }, [navigate, logged_in, props.current_page]);

    function logout() {
        fetch("/logout", {method: 'POST'})
            .then((response) => response.json())
            .then((response) => {
                set_logged_in(false);
                navigate("/logout");
            })
            .catch(error => {
                console.error(error);
            });
        navigate("/logout");
    }

    function getButtonClass(page_number: string) {
        return page_number === props.current_page ? "menuButtonSelected" : "menuButtonUnselected";
    }

    return <div className="menubarOuter">
        <div onClick={() => window.location.reload()}>
            <view className="logoView">
                <img className="logo" src={logo} alt="Logo" onClick={() => {props.set_current_page("/search-positions")}}/>
            </view>
        </div>
        <div className="menubar" >
            {logged_in && (
                <div className={"menubarButtons " + getButtonClass("/projects")} onClick={() => {props.set_current_page("/projects")}}>
                    <view className="menubarImg">
                        <img src={props.current_page === "/projects" ? db_eye_selected : db_eye} alt="db_search"/>
                    </view>

                    <div className="menubarTextProject">
                        Meine Projekte
                    </div>
                </div>
            )}

            {logged_in && (
                <div className={"menubarButtons " + getButtonClass("/search-positions")} onClick={() => {
                    if (props.current_page === '/search-positions'){
                        window.location.reload()
                    }
                    else {
                        props.set_current_page("/search-positions")
                    }
                }}>
                    <view className="menubarImg">
                        <img src={props.current_page === "/search-positions" ? db_search_selected : db_search} alt="db_search"/>
                    </view >
                    <div className="menubarText">
                        Suche
                    </div>
                </div>
            )}

            {logged_in && (
                <div className="menubarButtons menuButtonUnselected" onClick={() => {logout()}}>
                    <view className="logoutImg">
                        <img src={logout_img} alt="logout_img"/>
                    </view>
                    <div className="menubarText">
                        Abmelden
                    </div>
                </div>
            )}
        </div>
    </div>
}

export default Menubar