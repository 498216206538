import React, {useEffect, useState} from "react";
import InputSelect from "../components/InputSelect";
import triangle from "../images/triangle.svg";
import Hint from "../components/Hint";
import questionMark from "../images/questionmark.svg"

function AdvancedSearch(props: any) {
    const [advanced_search, set_advanced_search] = useState<boolean>(false);
    const [available_projects, set_available_projects] = useState<string[]>([]);

    const [short_description, set_short_description] = useState<boolean>(true);
    const [long_description, set_long_description] = useState<boolean>(true);
    const [is_valid, set_is_valid] = useState<boolean>(false);
    const [intern_only, set_intern_only] = useState<null | boolean>(null);
    const [project_names, set_project_names] = useState<any[]>([]);
    const [crafts, set_crafts] = useState<any[]>([]);
    const [units, set_units] = useState<any[]>([]);

    const [got_projects, set_got_projects] = useState<boolean>(false);
    const available_crafts = [
        {label: "kein spezifisches Gewerk", value: "Bauleistungen am Bauwerk"},
        {label: "Rohbauarbeiten (allgemein)", value: "Rohbauarbeiten"},
        {label: "Bauleistungen am Bauwerk", value: "Bauleistungen am Bauwerk"},
        {label: "Erdarbeiten", value: "Erdarbeiten"},
        {label: "Verbauarbeiten", value: "Verbauarbeiten"},
        {label: "Ramm-, Rüttel- und Pressarbeiten", value: "Ramm-, Rüttel- und Pressarbeiten"},
        {label: "Entwässerungskanalarbeiten", value: "Entwässerungskanalarbeiten"},
        {label: "Mauerarbeiten", value: "Mauerarbeiten"},
        {label: "Betonarbeiten", value: "Betonarbeiten"},
        {label: "Zimmer- und Holzbauarbeiten", value: "Zimmer- und Holzbauarbeiten"},
        {label: "Stahlbauarbeiten", value: "Stahlbauarbeiten"},
        {label: "Abdichtungsarbeiten", value: "Abdichtungsarbeiten"},
        {label: "Dachdeckungs- und Dachabdichtungsarbeiten", value: "Dachdeckungsarbeiten"},
        {label: "Klempnerarbeiten", value: "Klempnerarbeiten"},
        {label: "Gerüstarbeiten", value: "Gerüstarbeiten"},
        {label: "Ausbauarbeiten (allgemein)", value: "Ausbauarbeiten"},
        {label: "Naturwerksteinarbeiten", value: "Naturwerksteinarbeiten"},
        {label: "Betonwerksteinarbeiten", value: "Betonwerksteinarbeiten"},
        {label: "Putz- und Stuckarbeiten", value: "Putz- und Stuckarbeiten"},
        {label: "Wärmedämm-Verbundsysteme", value: "Wärmedämm-Verbundsysteme"},
        {label: "Trockenbauarbeiten", value: "Trockenbauarbeiten"},
        {label: "Vorgehängte hinterlüftete Fassaden", value: "Vorgehängte hinterlüftete Fassaden"},
        {label: "Fliesen- und Plattenarbeiten", value: "Fliesen- und Plattenarbeiten"},
        {label: "Estricharbeiten", value: "Estricharbeiten"},
        {label: "Tischlerarbeiten", value: "Tischlerarbeiten"},
        {label: "Parkett- und Holzpflasterarbeiten", value: "Parkett- und Holzpflasterarbeiten"},
        {label: "Rollladenarbeiten", value: "Rollladenarbeiten"},
        {label: "Metallbauarbeiten", value: "Metallbauarbeiten"},
        {label: "Verglasungsarbeiten", value: "Verglasungsarbeiten"},
        {label: "Maler- und Lackierarbeiten, Beschichtungen", value: "Maler- und Lackierarbeiten, Beschichtungen"},
        {label: "Korrosionsschutzarbeiten an Stahlbauten", value: "Korrosionsschutzarbeiten an Stahlbauten"},
        {label: "Bodenbelagarbeiten", value: "Bodenbelagarbeiten"},
        {label: "Tapezierarbeiten", value: "Tapezierarbeiten"},
        {label: "Raumlufttechnische Anlagen", value: "Raumlufttechnische Anlagen"},
        {label: "Heiz- und zentrale Wassererwärmungsanlagen", value: "Heiz- und zentrale Wassererwärmungsanlagen"},
        {label: "Gas-, Wasser- und Entwässerungsanl. innerh. v.Geb.", value: "Gas-, Wasser- und Entwässerungsanl. innerh. v.Geb."},
        {label: "Aufzugsanl., Fahrtreppen, Fahrsteige, Förderanl.", value: "Aufzugsanl., Fahrtreppen, Fahrsteige, Förderanl."},
        {label: "Gebäudeautomation", value: "Gebäudeautomation"},
        {label: "Dämm- und Brandschutzarbeiten an techn. Anlagen", value: "Dämm- und Brandschutzarbeiten an techn. Anlagen"},
        {label: "Blitzschutz-, Überspannungsschutz-, Erdungsanlagen", value: "Blitzschutz-, Überspannungsschutz-, Erdungsanlagen"},
        {label: "Elektro-, Sicherheits- u. informationstechn. Anl.", value: "Elektro-, Sicherheits- u. informationstechn. Anl."},
        {label: "Korrosionsschutzarbeiten an Stahlbauten", value: "Korrosionsschutzarbeiten an Stahlbauten"},
        {label: "Beschlagarbeiten", value: "Beschlagarbeiten"},
    ];

    const { set_filter } = props;

    let request = {
        headers: { "Content-Type": "application/json" },
        method: 'POST',
        body: JSON.stringify({ showGlobalProjects: true })
    };

    function convert_project_item_list(items: string[]) {
        let new_list: any = [];
        items.forEach(function (item){
            new_list.push({"label": item, "value": item });
        });
        return new_list;
    }

    function convert_crafts_item_list(available_crafts: { value: string, label: string }[]){
        let selectedItems: string[] = crafts.map(function (craft) {
            return craft.value;
        });

        let new_list: any = [];
        available_crafts.forEach((item: { value: string, label: string }) => {
            new_list.push({"label": item.label, "value": item.value, "isSelected": selectedItems.includes(item.value)});
        });
        return new_list;
    }

    function convert_units_for_dropdown(converted_units: any){
        let new_list: any = [];
        Object.keys(converted_units).forEach((unit: any) => {
            if(unit === ""){
                new_list.push({"label": "ohne Einheit", "value": unit});
            }
            else{
                new_list.push({"label": unit + " (" + converted_units[unit] + ")", "value": unit});
            }
        });
        return new_list;
    }

    function choice_to_values(choice: any){
        if (Array.isArray(choice)){
            let property: any = [];
            choice.forEach(function (craft: any){
                property.push(craft.value);
            })
            return property;
        }
        return choice.value;
    }

    useEffect(() => {
        let new_filter: { [key: string]: any } = {
            "short_description": short_description,
            "long_description": long_description,
            "is_valid": is_valid,
            "intern_only": intern_only,
            "project_names": choice_to_values(project_names),
            "crafts": choice_to_values(crafts),
            "units": choice_to_values(units),
        };
        set_filter(new_filter);
    },
        [set_filter, short_description, long_description, is_valid, project_names, crafts, intern_only, units])

    useEffect(() => {
        if (!got_projects){
            set_got_projects(true);
            fetch("/projects", request)
                .then((response) => response.json())
                .then((response) => {
                    set_available_projects(response["project_names"]);
                })
                .catch(error => {
                    console.error(error);
                })
        }
    })

    return (
        <div className="moreSearchOptionsOuter">
            <div className="moreSearchOptionsButton" onClick={() => set_advanced_search(!advanced_search)}>
                <div className="extraSearchPosText">
                    Erweiterte Suche
                </div>

                <div className="extraSearchPosImg">
                    <view>
                        <img src={triangle} alt="" className={"advancedSearchTriangleImg" + (advanced_search ? " triangleRotate" : "")}/>
                    </view>
                </div>
            </div>
            {advanced_search && (
                <div className="moreSearchOptionsField">
                    <div className="advanced_search_half">
                        <div className="advancedSearchOuter">
                            <div className="nextEachOther">
                                <input className="searchCheckbox" type="checkbox" defaultChecked={long_description}
                                       onChange={event => set_long_description(event.target.checked)}/>
                                <div className="advancedSearchText">Suche nur im Langtext</div>
                                <Hint
                                    textHint={'Suche nur in Langtexten der Leistungsverzeichnisse'}
                                    top={'0px'}
                                    className={'alignRight'}
                                    hintedComponent={
                                        <img className={'questionmarkRight'} src={questionMark} alt={'?'}/>
                                    }
                                    hintStyle={'hint'}
                                />
                            </div>
                        </div>
                        <div className="advancedSearchOuter">
                            <div className="nextEachOther">
                                <input className="searchCheckbox" type="checkbox" defaultChecked={short_description}
                                       onChange={event => set_short_description(event.target.checked)}/>
                                <div className="advancedSearchText">Suche nur im Kurztext</div>
                                <Hint
                                    textHint={'Suche nur in Kurztexten der Leistungsverzeichnisse'}
                                    top={'0px'}
                                    className={'alignRight'}
                                    hintedComponent={
                                        <img className={'questionmarkRight'} src={questionMark} alt={'?'}/>
                                    }
                                    hintStyle={'hint'}
                                />
                            </div>
                        </div>
                        <div className="advancedSearchOuter">
                            <div className="nextEachOther">
                                <input className="searchCheckbox" type="checkbox" defaultChecked={is_valid}
                                       onChange={event => set_is_valid(event.target.checked)}/>
                                <div className="advancedSearchText">Nur validierte</div>
                                <Hint
                                    textHint={'Suche nur in Leistungspoisitionen mit validiertem Kostenkennwert'}
                                    top={'0px'}
                                    className={'alignRight'}
                                    hintedComponent={
                                        <img className={'questionmarkRight'} src={questionMark} alt={'?'}/>
                                    }
                                    hintStyle={'hint'}
                                />
                            </div>
                        </div>
                        <div className="advancedSearchOuter">
                            <div className="nextEachOther">
                                <input className="searchCheckbox" type="checkbox" defaultChecked={Boolean(intern_only)}
                                       onChange={event => set_intern_only(event.target.checked)}/>
                                <div className="advancedSearchText">Nur Interne Projekte</div>
                                <Hint
                                    textHint={'Suche nur in internen Leistungsverzeichnissen'}
                                    top={'0px'}
                                    className={'alignRight'}
                                    hintedComponent={
                                        <img className={'questionmarkRight'} src={questionMark} alt={'?'}/>
                                    }
                                    hintStyle={'hint'}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="advanced_search_half">
                        <div className="advancedSearchOuter flexdirectionRow">
                            <div style={{width: '100%'}}>
                                <InputSelect set_function={(value: any) => {set_project_names(value)}}
                                             items={convert_project_item_list(available_projects)}
                                             value={project_names}
                                             placeholder="Suche nur in diesen Projekten"
                                             creatable_select={false}
                                             isMulti={true}
                                             filter_prop="project_names"
                                />
                            </div>

                            <Hint
                                textHint={'Suche nur in ausgewählten Projekten'}
                                top={'0px'}
                                className={'alignRight'}
                                hintedComponent={
                                    <img className={'questionmarkRight'} src={questionMark} alt={'?'}/>
                                }
                                hintStyle={'hint'}
                            />
                        </div>

                        <div className="advancedSearchOuter flexdirectionRow">
                            <div style={{width: '100%'}}>
                                <InputSelect
                                    className=""
                                    set_function={set_crafts}
                                    items={convert_crafts_item_list(available_crafts)}
                                    value={crafts}
                                    placeholder="Suche nur in diesen Gewerken"
                                    creatable_select={false}
                                    isMulti={true}
                                    filter_prop="crafts"
                                />
                            </div>
                            <Hint
                                textHint={'Suche nur in ausgewählten Gewerken'}
                                top={'0px'}
                                className={'alignRight'}
                                hintedComponent={
                                    <img className={'questionmarkRight'} src={questionMark} alt={'?'}/>
                                }
                                hintStyle={'hint'}
                            />

                        </div>

                        <div className="advancedSearchOuter flexdirectionRow">
                            <div style={{width: '100%'}}>
                                <InputSelect className=""
                                             set_function={set_units}
                                             items={convert_units_for_dropdown(props.available_units)}
                                             value={units}
                                             placeholder="Suche nur mit diesen Mengeneinheiten"
                                             creatable_select={false}
                                             isMulti={true}
                                             filter_prop="units"
                                />
                            </div>
                            <Hint
                                textHint={'Suche nur in Leistungspositionen mit ausgewählten Mengeneinheiten'}
                                top={'0px'}
                                className={'alignRight'}
                                hintedComponent={
                                    <img className={'questionmarkRight'} src={questionMark} alt={'?'}/>
                                }
                                hintStyle={'hint'}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default AdvancedSearch;