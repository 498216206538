import React, {useEffect} from "react";
import {useState} from "react";
import "../positionDetails.css";
import Detail from "./Detail";
import PriceBar from "./PriceBar";

function PositionDetail(props: any) {

    const month_map: any = {
        1: "Januar",
        2: "Februar",
        3: "März",
        4: "April",
        5: "Mai",
        6: "Juni",
        7: "Juli",
        8: "August",
        9: "September",
        10: "Oktober",
        11: "November",
        12: "Dezember",
    };

    return <div>
        <div className="div1">
            <div className="nextEachOther">
                <div className="divLeft border borderLeftBottom">
                    <div className="border borderTop padding">
                        <h3>Kurztext</h3>
                        {props.row["short_description"]}
                    </div>
                    <div className="border borderTop padding">
                        <h3>Langtext</h3>
                        {props.row["long_description"]}
                    </div>
                </div>
                <div className="divRight border borderFull padding">
                    <Detail property="Projektname intern: " property_value={props.row["project_name"]}/>
                    <Detail property="Name des Leistungsverzeichnisses: " property_value={props.row["craft_name"]}/>
                    <Detail property="Gewerkbezeichnung: " property_value={props.row["craft"]}/>
                    <Detail property="Gebäudeart: " property_value={props.row["category"]}/>
                    <Detail property="Jahr der Ausschreibung: " property_value={props.row["year"]}/>
                    <Detail property="Monat der Ausschreibung / Quartal: " property_value={month_map[props.row["month"]] + " / " + (Math.floor((props.row["month"] - 1) / 3 + 1))}/>
                    <Detail property="Ort/Bundesland: " property_value={props.row["location"]}/>
                    <Detail property="Auftragsvolumen: " property_value={props.ref_points(props.row["project_size"])}/>
                    <br/>
                    <Detail property="Kostenkennwert nicht indiziert: " property_value={props.price_formatter(props.row["cost_characteristic"]) + " €/" + props.row["unit"]}/>
                    <Detail property="Kostenkennwert indiziert" property_value={props.price_formatter(props.row["indexed_cost_characteristic"]) + " €/" + props.row["unit"]}/>
                    <Detail property="Indizierungsfaktor" property_value={(Math.round(1000 * props.row["indexed_cost_characteristic"] / props.row["cost_characteristic"]) / 1000).toString().replace(".", ",")}/>
                    <br/>
                    <Detail property="Menge: " property_value={props.ref_points(props.row["count"], false, true)}/>
                    <Detail property="Mengeneneinheit: " property_value={props.row["unit"]}/>
                    <Detail property="Ordnungszahl im Leistungsverzeichnis: " property_value={props.row["oz"]}/>
                    <Detail property="Validiert: " property_value={props.row["is_valid"] === 0 ? "Nein" : "Ja"}/>
                    <br/>
                    <Detail property="BauKoDat Projektnummer: " property_value={props.row["project_id"]}/>
                </div>
            </div>
            <div className="border borderLeftRightBottom padding">
                <h3>Manuelle Validierung</h3>
                <h4>Einheitspreise</h4>
                <PriceBar left={props.row["low_prices"]} middle={props.row["accepted_prices"]} right={props.row["high_prices"]} price_factor={props.row["indexed_cost_characteristic"] / props.row["cost_characteristic"]} price_formatter={props.price_formatter}/>
            </div>
        </div>
    </div>
}

export default PositionDetail
