import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";

function ForgotPassword(props: any){
    const [email_saved_success, set_email_saved_success] = useState<null | boolean>(null);
    const [email, setEmail] = useState("");
    let navigate = useNavigate();

    function forgot_password(email: string) {
        let request = {
            headers: { "Content-Type": "application/json" },
            method: "POST",
            body: JSON.stringify({ email }),
        }
        fetch("forgot-password", request)
            .then((response) => response.json())
            .then((response) => {
                if (response["email_saved"]) {
                    set_email_saved_success(true);
                }
                else {
                    set_email_saved_success(false);
                }
            })
            .catch(error => {
                console.error(error);
                set_email_saved_success(false);
            })
    }

    return <div>
        <div className="loginOuter2">
            <div className="loginOuter">
                <div className="forgotPasswordTitle">Passwort vergessen</div>
                <div className="loginText">Sende uns deine E-Mail Adresse, mit der du bei BauKoDat registriert bist und lasse dein Passwort zurücksetzen</div>
                <input className="loginExtras login" placeholder="Deine BauKoDat E-Mail" onChange={(event) => {setEmail(event.target.value)}}/>
                <button className="loginBtn loginButton" onClick={() => forgot_password(email)}>Passwort zurücksetzen</ button>
                <div className="loginForgotPassword" onClick={() => navigate("/login")}>Zur Anmeldung</div>
                {email_saved_success != null && email_saved_success && (
                    <div className="login_email_saved">Die E-mail wurde erfolgreich abgelegt</div>
                )}
                {email_saved_success != null && !email_saved_success && (
                    <div className="login_email_not_saved">Das hat nicht funktioniert. Probiere es bitte erneut</div>
                )}
            </div>
        </div>
    </div>
}

export default ForgotPassword