import React from "react";
import "../styles.css";
import {Link} from "react-router-dom";

const Footer = (props: any) => {
    return(
        <div className="footer">
            <Link to="/imprint">
                <button className="footerButtons" onClick={() => props.set_current_page('/imprint')}>
                    Impressum
                </button>
            </Link>
        </div>
)
}

export default Footer;