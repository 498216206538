import React, {useState, useRef, useEffect} from "react";
import "../styles.css";
import "../upload.css";
import "../placeholder.css";
import InputSelect from "../components/InputSelect";
import search_img from "../images/search.svg";
import UploadError from "../components/error_messages/UploadError";
import logo from "../images/logo.svg";
import csvUpload from "../images/csvUpload.svg"

function Upload() {
    const lv_ref = useRef<HTMLInputElement>(null);
    const price_ref = useRef<HTMLInputElement>(null);

    const [lv, set_lv] = useState<FileList | null>(null);
    const [prices, set_prices] = useState<FileList | null>(null);
    const [craft_name, set_craft_name] = useState<any | null>(null);
    const [year, set_year] = useState<any | null>(null);
    const [month, set_month] = useState<any | null>(null);
    const [location, set_location] = useState<any | null>(null);
    const [craft, set_craft] = useState<any | null>(null);
    const [category, set_category] = useState<any | null>(null);
    const [project_name, set_project_name] = useState<any | null>(null);

    const [upload_success, set_upload_success] = useState<null | boolean>(null);
    const [currently_uploading, set_currently_uploading] = useState(false);

    const [got_projects, set_got_projects] = useState<boolean>(false);
    const [available_projects, set_available_projects] = useState<string[]>([]);

    const [upload_msg, set_upload_msg] = useState<null | string>(null);
    const [csv_files, set_csv_files] = useState<any>(null);

    const month_mapping: any = [
        {"label": "Januar", "value": 1},
        {"label": "Februar", "value": 2},
        {"label": "März", "value": 3},
        {"label": "April", "value":4},
        {"label": "Mai", "value": 5},
        {"label": "Juni", "value": 6},
        {"label": "Juli", "value": 7},
        {"label": "August", "value": 8},
        {"label": "September", "value": 9},
        {"label": "Oktober", "value": 10},
        {"label": "November", "value": 11},
        {"label": "Dezember", "value": 12}
    ];
    
    const available_crafts_old = [
        "Bauleistungen am Bauwerk",
        "Rohbauarbeiten",
        
        "Erdarbeiten",
        "Verbauarbeiten",
        "Ramm-, Rüttel- und Pressarbeiten",
        "Entwässerungskanalarbeiten",
        "Mauerarbeiten",
        "Betonarbeiten",
        "Zimmer- und Holzbauarbeiten",
        "Stahlbauarbeiten",
        "Abdichtungsarbeiten",
        "Dachdeckungs- und Dachabdichtungsarbeiten",
        "Klempnerarbeiten",
        "Gerüstarbeiten",
        "Ausbauarbeiten",
        "Naturwerksteinarbeiten",
        "Betonwerksteinarbeiten",
        "Putz- und Stuckarbeiten",
        "Wärmedämm-Verbundsysteme",
        "Trockenbauarbeiten",
        "Vorgehängte hinterlüftete Fassaden",
        "Fliesen- und Plattenarbeiten",
        "Estricharbeiten",
        "Tischlerarbeiten",
        "Parkett- und Holzpflasterarbeiten",
        "Rollladenarbeiten",
        "Metallbauarbeiten",
        "Verglasungsarbeiten",
        "Maler- und Lackierarbeiten, Beschichtungen",
        
        "Korrosionsschutzarbeiten an Stahlbauten",
        "Bodenbelagarbeiten",
        "Tapezierarbeiten",
        "Raumlufttechnische Anlagen",
        "Heiz- und zentrale Wassererwärmungsanlagen",
        "Gas-, Wasser- und Entwässerungsanl. innerh. v.Geb.",
        "Nieder- und Mittelspannungsanlagen bis 36 kV",
        "Blitzschutzanlagen",
        "Aufzugsanl., Fahrtreppen, Fahrsteige, Förderanl.",
        "Gebäudeautomation",
        "Dämm- und Brandschutzarbeiten an techn. Anlagen",
    ];

    
    
    
    const available_crafts = [
        'Bauleistungen am Bauwerk',
        'Rohbauarbeiten',
        
        'Erdarbeiten',
        'Verbauarbeiten',
        'Ramm-, Rüttel- und Pressarbeiten',
        'Entwässerungskanalarbeiten',
        'Mauerarbeiten',
        'Betonarbeiten',
        'Zimmer- und Holzbauarbeiten',
        'Stahlbauarbeiten',
        'Abdichtungsarbeiten',
        'Dachdeckungsarbeiten',
        'Klempnerarbeiten',
        'Gerüstarbeiten',
        'Ausbauarbeiten',
        'Naturwerksteinarbeiten',
        'Betonwerksteinarbeiten',
        'Putz- und Stuckarbeiten',
        'Wärmedämm-Verbundsysteme',
        'Trockenbauarbeiten',
        'Vorgehängte hinterlüftete Fassaden',
        'Fliesen- und Plattenarbeiten',
        'Estricharbeiten',
        'Tischlerarbeiten',
        'Parkett- und Holzpflasterarbeiten',
        'Rollladenarbeiten',
        'Metallbauarbeiten',
        'Verglasungsarbeiten',
        'Maler- und Lackierarbeiten, Beschichtungen',
        'Bodenbelagarbeiten',
        'Tapezierarbeiten',
        'Raumlufttechnische Anlagen',
        'Heiz- und zentrale Wassererwärmungsanlagen',
        'Gas-, Wasser- und Entwässerungsanl. innerh. v.Geb.',
        'Aufzugsanl., Fahrtreppen, Fahrsteige, Förderanl.',
        'Gebäudeautomation',
        'Dämm- und Brandschutzarbeiten an techn. Anlagen',
        'Blitzschutz-, Überspannungsschutz-, Erdungsanlagen',
        'Elektro-, Sicherheits- u. informationstechn. Anl.',
        'Korrosionsschutzarbeiten an Stahlbauten',
        'Beschlagarbeiten',
    ];
    
    let csv_request_options: any = {
        method: 'POST',
    }

    function range(start: number, end: number, step: number): number[]{
        let result = [];
        let direction: number = end > start ? 1 : -1;
        if (step * direction <= 0){
            return [];
        }
        while (direction * start < direction * end) {
            result.push(start);
            start += step
        }
        return result;
    }

    useEffect(() => {
        if(!currently_uploading){
            return;
        }
        if(csv_files === null){
            return;
        }
        if (!csv_files[0] || !csv_files[1]) {
            set_currently_uploading(false);
            set_upload_msg("Bitte sowohl den Preisspiegel als auch das Leistungsverzeichnis auswählen");
            set_upload_success(false);
            return;
        }
        let lv_file = csv_files[0][0].name
        let price_file = csv_files[1][0].name
        let lvMimeType = lv_file.split('.')[1]
        if (lvMimeType !== 'csv' && lvMimeType !== 'xlsx') {
            set_currently_uploading(false);
            set_upload_msg("Das Leistungsverzeichnis muss eine csv Datei sein.");
            set_upload_success(false);
            return;
        }
        let priceMimeType = price_file.split('.')[1]
        if (priceMimeType !== 'csv' && priceMimeType !== 'xlsx') {
            set_currently_uploading(false);
            set_upload_msg("Der Preisspiegel muss eine csv Datei sein.");
            set_upload_success(false);
            return;
        }

        let data = new FormData();
        data.append("lv", csv_files[0][0]);
        data.append("prices", csv_files[1][0]);
        data.append("project_name", project_name);
        data.append("craft", craft);
        data.append("craft_name", craft_name);
        data.append("month", month);
        data.append("location", location);
        data.append("year", year);
        data.append("category", category);
        data.append("lv_file_name", lv ? lv[0].name : "");
        data.append("price_file_name", prices ? prices[0].name : "");

        if (project_name == null || craft_name == null || craft == null){
            set_upload_msg("Bitte alle Felder ausfüllen");
            set_currently_uploading(false);
            set_upload_success(false);
            return;
        }

        csv_request_options.body = data;
        fetch("/upload-csv", csv_request_options)
            .then((response) => response.json())
            .then((response) => {
                if ("success" in response) {
                    set_upload_success(response["success"]);
                }
                else {
                    set_upload_success(false);
                }
                set_currently_uploading(false);
            })
            .catch(error => {
                console.error(error);
                set_upload_success(false);
                set_currently_uploading(false);
            });
    }, [currently_uploading])

    function upload(csv_files: any | undefined){
        set_csv_files(csv_files);
        if (currently_uploading){
            set_upload_msg("Es wird bereits hochgeladen. Bitte einen Moment warten");
            set_upload_success(false);
            return;
        }
        set_currently_uploading(true);

    }

    function uploadLv() {
        lv_ref?.current?.click();
    }

    function uploadPrice() {
        price_ref?.current?.click();
    }

    function lv_text(){
        if(lv){
            let file_path = lv[0].name.split(".")
            let file_type = file_path.pop()
            let file_name = file_path.pop()
            return file_name + "." + file_type
        }
        return ""
    }

    function price_text(){
        if(prices){
            let file_path = prices[0].name.split(".")
            let file_type = file_path.pop()
            let file_name = file_path.pop()
            return file_name + "." + file_type
        }
        return ""
    }

    function upload_button_class(){
        let default_class = "uploadButton"
        if(currently_uploading){
            default_class += " currentlyUploadingButton"
        }
        return default_class;
    }

    function convert_item_list(items: (number | string)[]){
        let craft_value_mapping: any = {
            "Rohbauarbeiten": "Rohbauarbeiten (allgemein)",
            "Ausbauarbeiten": "Ausbauarbeiten (allgemein)",
            "Bauleistungen am Bauwerk": "kein spezifisches Gewerk",
        };

        let new_list: any = [];
        items.forEach(function (item){
            new_list.push({"label": item in craft_value_mapping ? craft_value_mapping[item] : item, "value": item});
        })
        return new_list;
    }

    let request = {
        headers: { "Content-Type": "application/json" },
        method: 'POST',
        body: JSON.stringify({ showGlobalProjects: false })
    };

    useEffect(() => {
        if (!got_projects){
            set_got_projects(true);
            fetch("/projects", request)
                .then((response) => response.json())
                .then((response) => {
                    set_available_projects(response["project_names"]);
                })
                .catch(error => {
                    console.error(error);
                })
        }
    }, [])

    return <div className={'centerFullUpload'}>
        <div className="uploadFull">
            <div className="title">Datenbank erweitern</div>
            <div className="outerUpload">
                <div className="outerInputSelectUpload">
                    <InputSelect className="inputSelect"
                                 set_function={set_project_name}
                                 items={convert_item_list(available_projects)}
                                 placeholder="Projekt Name (Ein bestehendes Projekt auswählen oder ein neues Projekt erstellen)"
                                 creatable_select={true}
                                 isMulti={false}
                                 outerClassName={'uploadInputSelect'}
                                 classNamePrefix={'inputSelectCustomPrefix'}
                    />
                    <InputSelect className="inputSelect"
                                 set_function={set_year}
                                 items={convert_item_list(range((new Date()).getFullYear(), 1967, -1))}
                                 placeholder="Jahr"
                                 creatable_select={false}
                                 isMulti={false}
                                 outerClassName={'uploadInputSelect'}
                                 classNamePrefix={'inputSelectCustomPrefix'}
                    />
                    <InputSelect className="inputSelect"
                                 set_function={set_month}
                                 items={month_mapping}
                                 placeholder="Monat"
                                 creatable_select={false}
                                 isMulti={false}
                                 outerClassName={'uploadInputSelect'}
                                 classNamePrefix={'inputSelectCustomPrefix'}
                    />
                    <input className="upload uploadInputSize craftPlacing" style={{flexGrow: 1}} type="text"
                           placeholder="Ort/Bundesland" onChange={event => set_location(event.target.value)}/>

                    <InputSelect className="inputSelect"
                                 set_function={set_category}
                                 items={convert_item_list(["Wohngebäude", "Bürogebäude", "Gewerbliche Betriebsgebäude"])}
                                 placeholder="Gebäudeart"
                                 creatable_select={false}
                                 isMulti={false}
                                 outerClassName={'uploadInputSelect'}
                                 classNamePrefix={'inputSelectCustomPrefix'}
                    />

                    <InputSelect className="inputSelect"
                                 set_function={set_craft}
                                 items={convert_item_list(available_crafts)}
                                 placeholder="Gewerk"
                                 creatable_select={false}
                                 isMulti={false}
                                 outerClassName={'uploadInputSelect'}
                                 classNamePrefix={'inputSelectCustomPrefix'}
                    />

                    <input className="upload uploadInputSize craftPlacing" style={{flexGrow: 1}} type="text"
                           placeholder="Gewerk Name" onChange={event => set_craft_name(event.target.value)}/>

                </div>

                <div className="outerFilesUpload">
                    <div>
                        <div className="csvUpload" onClick={uploadLv}>
                            <div className="csvFileUploadTitle">Leistungsverzeichnis</div>
                            <img className="csvUploadImg" src={csvUpload} alt="-> csv <-"/>
                            <div className="csvFilename">{lv_text()}</div>
                        </div>
                        <div className="csvUpload" onClick={uploadPrice}>
                            <div className="csvFileUploadTitle">Preisspiegel</div>
                            <img className="csvUploadImg" src={csvUpload} alt="-> csv <-"/>
                            <div className="csvFilename">{price_text()}</div>
                        </div>
                    </div>
                    <input ref={lv_ref} className="hide" type="file" name="csv" onChange={event => set_lv(event.target.files)}/>
                    <input ref={price_ref} className="hide" type="file" name="csv" onChange={event => set_prices(event.target.files)}/>
                </div>
            </div>
            <div className={upload_button_class()} onClick={() => upload([lv, prices])} >
                Gewerk hochladen
            </div>
            <div className="searchRotatePosition">
                {currently_uploading && (
                    <view><img src={search_img} alt="..." className="searchRotate searchFeedbackSize"/></view>
                )}
            </div>
            {upload_success != null && upload_success && (
                <div>
                    Upload erfolgreich
                </div>
            )}
            {(upload_success != null) && !upload_success && (
                <UploadError upload_msg={upload_msg}/>
            )}
        </div>
    </div>
}

export default Upload