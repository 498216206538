import React, {useEffect} from "react";
import "../detail.css";

function Detail(props: any) {

    return <div>
        <div className="nextEachOther textFont">
            <div className="textFont propertyFont placing">{props.property} </div>
            <div>{props.property_value}</div>
        </div>
    </div>
}

export default Detail
