import AppLayout from "./components/AppLayout";

function App() {

    return (
        <div>
            <AppLayout />
        </div>
  );
}

export default App;
