import React, {useEffect} from "react";
import "./errorMessages.css";
import sad_face from "../../images/sad_face.svg";

function SearchError() {
    return <div className="outer">
        <div className="background">
            <div>
                <div className="fontSizeTitle">Es gibt anscheinend keine Übereinstimmungen für deine Suchanfrage</div>

                <div className="nextEachOther2">
                    <p>Suchtipps</p>
                    <ul>
                        <li>Achte darauf, dass die Wörter richtig geschrieben sind</li>
                        <li>Probiere andere Schreibweisen oder Wörter</li>
                        <li>Überprüfe die Sucheinstellungen in der erweiterten Suche</li>
                    </ul>
                </div>
                <div className="nextEachOther2">
                    <img className="margin" src={sad_face} alt=":(" />
                </div>
            </div>
        </div>
    </div>
}

export default SearchError
