import React, {useState, useEffect} from "react";
import LvBar from "../components/LvBar";
import add_project from "../images/add_project.svg";
import ProjectsEmpty from "../components/error_messages/ProjectsEmpty"


function Projects(props: any) {
    let [projects, set_projects] = useState<any>([])

    const test_projects = {
        "projekt 1": {
            "year": 2010,
            "category": "Wohngebäude",
            "crafts": [
                {"craft": "rohbauarbeiten", "craft_name": "rohbau"},
                {"craft": "erdarbeiten", "craft_name": "erde"}
            ]
        },
        "projekt 2": {
            "year": 2010,
            "category": "Gewerbliche Betriebsgebäude",
            "crafts": [{"craft": "gerüstarbeiten", "craft_name": "gerüst"}]
        },
        "projekt 3": {
            "year": 2010,
            "category": "Bürogebäude",
            "crafts": [{"craft": "gerüstarbeiten", "craft_name": "gerüst"}]
        },
        "projekt 4": {
            "year": 2010,
            "category": "Wohngebäude",
            "crafts": [{"craft": "gerüstarbeiten", "craft_name": "gerüst"}]
        },
    }

    let [last_updated, set_last_updated] = useState<number>(0);

    let request_body: any = {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ showGlobalProjects: true })
    }

    useEffect(() => {
        if (new Date().getTime() - last_updated < 1000) {
            return;
        }
        fetch("/get-projects", request_body)
            .then((response) => response.json())
            .then((response) => {
                set_last_updated(new Date().getTime());
                if (response["projects"].length !== projects.length)
                {
                    set_projects(response["projects"]);
                }
            })
        .catch(error => {
            console.error(error);
        });
    })

    return (
        <div>
            <div className="projectTitle">Meine Projekte</div>
            <div className="projectsContainer">
                <div className="projects newProjectOuter">
                    <div className="newProject" onClick={() => {props.set_current_page("/upload")}}>
                        Neues Projekt hinzufügen
                    </div>
                    {false && (
                        <view className="addProjectImg">
                            <img className="add_project" src={add_project} alt="add_project"/>
                        </view>
                    )}
                </div>
                {projects && (
                    Object.keys(projects).map((project: any, index: any) => (
                        <div className="projects split" key={index}>
                            <div>
                                <div className="projectHeader">{project}</div>
                                <div className="projectInfo">
                                    <div className="projectInfoInner">{projects[project]["year"]}</div>
                                    <div className="projectInfoInner">{projects[project]["category"]}</div>
                                    <div className="projectInfoInner">{projects[project]["location"]}</div>
                                </div>
                                {projects[project]["crafts"].map((craft_property: any, index2: any) => (
                                    <div key={index2}>
                                        <div className="projectCraftNames">{craft_property["craft_name"]}</div>
                                    </div>
                                ))}
                            </div>
                            <LvBar craft_count={projects[project]["crafts"].length}/>
                        </div>
                    ))
                )}
                {(!projects) && (
                    <ProjectsEmpty />
                )}
            </div>

        </div>

    )
}

export default Projects