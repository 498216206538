import React, {useState, useEffect} from "react";

function SearchDone(props: any){
    const [factor, set_factor] = useState(1);
    const [counter, set_counter] = useState(0);

    useEffect(() => {
        const duration = 5000;
        let dif = (new Date()).getTime() - props.lastSuccessSearch.getTime();
        let new_factor = Math.max(0, (dif / duration));
        if(new_factor === 0){
            props.setLastSuccessSearch(null);
        }
        // f(x) = - x^4 + 1 (0 -> 1; 1 -> 0)
        set_factor(-(new_factor ** 4) + 1);
        set_counter(counter + 1);

    }, [factor, counter]);

    return <div>
        <div style={{"backgroundColor": '#c2dbda', "color": `rgba(0, 0, 0, ${factor})`}}>
            Suche nach Kostenkennwerten erfolgreich aktualisiert
        </div>
    </div>
}

export default SearchDone