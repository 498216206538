import React, {useEffect} from "react";
import "../styles.css";
import {useState} from "react";
import AdvancedSearch from "../pages/AdvancedSearch";
import lupe from "../images/lupe.svg"
import questionmark from "../images/questionmark.svg"
import Hint from "./Hint";

function Search({searching, set_searching, get_positions, setLastSuccessSearch, available_units}: any) {
    const [input, set_input] = useState<string>("");
    let [filter, set_filter] = useState<any>({"texts": []})

    function format_user_input(): string[][]{
        // add words in quotationMarks to spaceSeparated words first
        let regex = /"[^"]*"/g
        let quotationMarkMatches = Array.from(input.matchAll(regex), match => match[0]);
        let quotationMarkFreeInput = input.replace(regex, '');

        // split at the spaces
        let spaceSeparated: string[] = quotationMarkMatches.concat(quotationMarkFreeInput.split(' ').filter(word => {
            return word !== '';
        }));

        // split each at underscore
        let spaceSeparatedUnderscoreSeperated = spaceSeparated.map(spaceSeparated => {
            return spaceSeparated.split('_').filter(underscoreSeperated => {
                return underscoreSeperated !== '';
            });
        });

        return spaceSeparatedUnderscoreSeperated;
    }

    function handleKeyDown(event: any){
        switch( event.keyCode ) {
            case 13:
                get_positions(format_user_input(), filter);
                set_searching(true);
                break;
            default:
                break;
        }
    }

    function search(){
        set_searching(true);
    }

    useEffect(() => {
        if(searching){
            get_positions(format_user_input(), filter);
        }
    }, [searching])

    return <div className="outerInnerSearch">
        <div className="searchBar" onKeyDown={(event) => {
            handleKeyDown(event)
        }}>
            <div className="searchInput">
                <Hint
                    textHint={<>
                        <strong>Suchregeln in BauKoDat</strong> <br />
                        <ul style={{textAlign: 'left', paddingLeft: '20px'}}>
                            <li><p><strong>Leerzeichen</strong> zwischen Wörtern werden als "<strong>oder</strong>" genutzt. Beispiel: [Fertigteil Treppenlaufplatte]. Es wird nach Ergebnissen mit Fertigteil oder Treppenlaufplatte oder auch nach nur nach einem der beiden Wörter gesucht.</p></li>
                            <li><p><strong>Unterstriche</strong> (_) zwischen Wörtern werden als "<strong>und</strong>" genutzt. Beispiel: [Fertigteil_Treppenlaufplatte]. Es wird nach Ergebnissen mit beiden Wörtern gesucht, unabhängig der Reihenfolge.</p></li>
                            <li><p>In <strong>Anführungszeichen</strong> geschriebene Wörter suchen nach der <strong>genauen Übereinstimmung</strong>. Beispiel: ["Fertigteil Treppenlaufplatte"]. Es wird nach Ergebnissen mit genau diesen Wörtern und genau dieser Reihenfolge gesucht.</p></li>
                            <li><p>Auf Groß- und Kleinschreibung wird nicht geachtet.</p></li>
                        </ul>
                    </>}
                    top={'-0px'}
                    hintedComponent={
                        <view className="lupeView">
                            <img className="lupe" src={lupe} alt="lupe"/>
                            <img className={'questionmark'} src={questionmark} alt={'?'}/>
                        </view>
                    }
                    hintStyle={'hintBigger'}
                />
                <input className="onlySearchInput"
                       type="text"
                       placeholder="Nach Kostenkennwerten suchen"
                       onChange={event => set_input(event.target.value)}
                />
                <div className="searchSubmit" onClick={search}>
                    <div>Suche</div>
                </div>
            </div>

            
        </div>
        <AdvancedSearch set_filter={set_filter} available_units={available_units}/>
    </div>
}

export default Search
