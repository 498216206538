import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";

const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [wrong_login, set_wrong_login] = useState(false);
    let navigate = useNavigate();

    let login_request = {
        headers: { "Content-Type": "application/json" },
        method: 'POST',
        body: JSON.stringify({ username: username, password })
    };

    function login() {
        fetch("/login", login_request)
            .then((response) => response.json())
            .then((response) => {
                if (response["logged_in"]) {
                    set_wrong_login(false);
                    navigate("/search-positions");
                }
                else {
                    set_wrong_login(true);
                }

            })
            .catch(error => {
                console.error(error);
            });
    }

    function handleKeyDown(event: any){
        switch( event.keyCode ) {
            case 13:
                login()
                break;
            default:
                break;
        }
    }

    return (
        <div className="loginOuter2">
            <div className="loginOuter">
                <div className="loginTitle">Anmelden</div>
                <div className="loginText">Melde dich an und suche nach Kostenkennwerten in tausenden
                    Leistungspositionen
                </div>
                <input className="loginExtras login" type="text" placeholder="Benutzername"
                       onChange={event => setUsername(event.target.value)} onKeyDown={(event) => {
                    handleKeyDown(event)
                }}/>
                <input className="loginExtras login" type="password" placeholder="Passwort"
                       onChange={event => setPassword(event.target.value)} onKeyDown={(event) => {
                    handleKeyDown(event)
                }}/>
                {wrong_login && (
                    <div className="wrongLogin">E-mail oder Passwort ist nicht korrekt</div>
                )}
                {/*<div className="loginForgotPassword" onClick={() => navigate("/forgot-password")}>Passwort vergessen?</div>*/}
                <a className="registerBtn" href={'https://www.baukodat.com/#SITE_FOOTER'} target="_blank" rel="noopener noreferrer">Registrieren</a>
                <button className="loginBtn loginButton" onClick={login}>Anmelden</ button>

            </div>
        </div>
    )
}

export default Login